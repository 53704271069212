<template>
  <div class="map-container">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMap">
      <div style="height: 100%; width: 100%">
        <div id="map" ref="map" class="map" />
        <LeftCarrier class="left-card" :current-goods-order="currentGoodsOrder" :all-car-data="allCarList" @leftSearchMark="leftSearchMark" />
        <RightPanel
          class="right-card"
          :goods-order-list="goodsOrderList"
          :all-car-data="allCarList"
          @rightShowCarLog="rightShowCarLog"
          @selectGoodsOrder="selectGoodsOrder"
        />
      </div>
    </fullscreen>
    <el-dialog title="轨迹监控" :visible.sync="dialogVisible" class="map-dialog-wrap" :before-close="() => (dialogVisible = false)" width="90%" top="0">
      <CarLog v-if="dialogVisible" :car-id="currentClickCarInfo.id" />
    </el-dialog>
  </div>
</template>

<script>
import LeftCarrier from './components/leftCarrier';
import RightPanel from './components/rightPanel';
import CarLog from './carLog/index';
import AMapLoader from '@amap/amap-jsapi-loader';
import goods from '@/api/transport/goods';
import supplyTask from '@/api/goodsOrder/supplyTask';
import { syncShareGoodsOrderDetail } from '@/api/transport/monitor/transportMap';

import { wsUrl } from '@/config/settings';
// import { dataToken } from '@/api/user';
import { parseTime } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'BgMap',
  filters: {
    parseTime,
  },
  components: { LeftCarrier, RightPanel, CarLog },
  data() {
    return {
      AMap: null,
      goodsOrderList: [], // 货单列表
      currentGoodsOrder: {}, // 当前的货单数据
      allCarList: [], // 当前货单下所有车单列表数据
      currentClickCarInfo: {}, // 当前点击得车单（辆）信息
      // geocoder: null,
      dialogVisible: false, // 车单履约弹窗
      fullscreen: false,
      carMap: new Map(),
      carMarkerMap: new Map(),
    };
  },
  computed: {
    ...mapGetters({
      socket: 'carMonitor/socket',
      currentCarData: 'carMonitor/currentCar',
      // carEventData: 'carMonitor/carEventData',
    }),
  },
  watch: {
    currentCarData(data) {
      // console.log(this.carMap, 'this.carMap');
      // console.log(data, 'currentCarData---data');
      // 处理数据
      if (!this.carMap.has(data.a)) {
        // console.log(data, '---no');
        // this.carNum.finish--;
        // 先画点
        const carMarker = this.drawCarMark(data.a, `${data.b / 1000000},${data.c / 1000000}`, data.f, data.d, true);
        this.carMarkerMap.set(data.a, carMarker);
        // const newData = this.carData.finish;
        // newData.forEach((item, index) => {
        //   if (item.b === data.a) {
        //     newData.splice(index, 1);
        //   }
        // });
        // this.carData.finish = newData;
        // // let i = this.carData.out.findIndex((value) => value.b === data.a);
        // // this.carData.out.splice(i, 1);
      } else {
        // console.log(data, '---yes');
        // 有车执行动画
        const fCar = this.carMap.get(data.a);
        // console.log(fCar, 'fCar');
        if (fCar.b !== data.b && fCar.c !== data.c) {
          const currentMark = this.carMarkerMap.get(data.a)[0];
          const currentText = this.carMarkerMap.get(data.a)[1];
          const currentText1 = this.carMarkerMap.get(data.a)[2];
          const markHtml3 = `<div class="${this.carClassName(data.d)}" ><div class="speed-item1" >${data.d}</div><div class="speed-item2" >km/h</div></div>`;
          currentText1.setText(markHtml3);
          this.carMoving(currentMark, currentText, currentText1, `${data.b / 1000000},${data.c / 1000000}`, +data.d);
        }
      }
      this.carMap.set(data.a, data);
    },
  },
  created() {
    this.toggleCollapse();
    // this.getGoodsOrderList();
    this.getGoodsDetail();
  },
  destroyed() {
    this.$store.commit('common/setCollapse', false);
    this.$store.dispatch('carMonitor/CLOSE_SOCKED');
  },
  mounted() {
    this.socketInitAndOpen();
  },
  methods: {
    toggleCollapse() {
      setTimeout(() => {
        this.$store.commit('common/setCollapse', true);
      });
    },
    // 全屏
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    socketInitAndOpen() {
      // var url = "ws://192.168.1.140:8080/ws";
      const res = {
        d: '15139fef1515149921bc8c4803f2',
      };
      // dataToken()
      //   .then((res) => {
      if (res.d) {
      // var url = "ws://106.12.35.99:9101/ws";
        // const url = 'ws://192.168.1.123:9300/transport/ws/11/22';
        // wsUrl
        this.$store.dispatch('carMonitor/SOCKET_INIT', wsUrl);
        // this.$store.dispatch('carMonitor/SOCKET_OPEN', { msg: res.d, type: [0, 1, 0] });
      } else {
        this.$message.error('websocket的token获取失败');
      }
    // })
    // .catch((e) => {
    //   console.log('err', e);
    // })
    // .finally(() => {
    // this.initMap();
    // this.getCarList(+this.$store.state.common.userInfo.companyId);
    // });
    },
    getGoodsDetail() {
      const params = {
        userId: this.$route.query.uId,
        custId: this.custId || '',
        gId: this.$route.query.orderId || '',
      };
      syncShareGoodsOrderDetail(params).then((res) => {
        if (res) {
          const { loadAddrPoint, unloadAddrPoint } = res;
          // const { receiptTotal = {}, carState = {}, carList = [], custList = [] } = extTypeMap;
          // this.orderId = res.id;
          this.currentGoodsOrder = res;
          // this.receiptTotal = receiptTotal;
          // this.carState = carState;
          // this.custList = custList;
          // this.carList = carList;
          const build = [];
          for (let i = 0; i <= res.custList.length; i++) {
            if (this.carList[i]) {
              this.carList[i].forEach((item) => {
                build.push(item.truckNo);
                this.totalCarList.push(item);
              });
            }
          }
          this.initSocketHandle(build);
          if (loadAddrPoint && unloadAddrPoint) {
            const startPoint = [loadAddrPoint.split(',')[0], loadAddrPoint.split(',')[1]];
            const endPoint = [unloadAddrPoint.split(',')[0], unloadAddrPoint.split(',')[1]];
            this.planLineHandle(startPoint, endPoint);
          }
        }
      });
    },
    // 获取货单列表 并取第一条货单内容默认展示
    getGoodsOrderList() {
      const params = {
        size: 10,
        current: 1,
        // enable: 1, // 0未承接 1货源已承接
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      goods.orderGoodsTransList(params).then(res => {
        this.goodsOrderList = res?.records || [];
        this.currentGoodsOrder = this.goodsOrderList[0] || {};
      }).finally(() => {
        this.initMap();
      });
    },
    initMap() {
      AMapLoader.load({
        key: '2ce614857c44af9c3cc5f2691e537ed9',
        version: '1.4.15',
        // 'AMap.Geocoder',
        plugins: ['AMap.Driving', 'AMap.MoveAnimation', 'AMap.MouseTool', 'AMap.LabelMarker'],
        AMapUI: {
          version: '1.1',
          plugins: [],
        },
        Loca: {
          version: '1.3.2',
        },
      })
        .then((AMap) => {
          this.mapInstance = new AMap.Map('map');
          this.AMap = AMap;
          // this.geocoder = new AMap.Geocoder();
          // this.initDrawArea(this.mapInstance, this.AMap); // 画区域
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          // this.currentGoodsOrder.id
          this.getAddressList();
          setTimeout(() => {
            this.getCarList();
          }, 500);
        });
    },
    // 获取车单列表（车辆列表）
    getCarList(id) {
      // getCarList({ a: id }).then((res) => {
      const res = {
        data: { d: [
          // {
          //   'a': 1765,
          //   'b': '冀EP6148',
          //   'c': 3082,
          //   'd': 'xin2',
          // },
          // {
          //   'a': 1766,
          //   'b': '苏A9B716',
          //   'c': 1056,
          //   'd': '萧纪聪',
          // },
          // {
          //   'a': 1767,
          //   'b': '津C02312',
          //   'c': 3087,
          //   'd': '新增司机',
          // },
          // {
          //   'a': 1768,
          //   'b': '津C02303',
          //   'c': 2615,
          //   'd': '陈家佳',
          // },
          // {
          //   'a': 1769,
          //   'b': '冀D3F819',
          //   'c': 2598,
          //   'd': '毛晓晓',
          // },
          // {
          //   'a': 1856,
          //   'b': '苏C98K89',
          //   'c': 0,
          // },
          // {
          //   'a': 1857,
          //   'b': '苏AGDE89',
          //   'c': 0,
          // },
          // {
          //   'a': 2015,
          //   'b': '苏AGDS98',
          //   'c': 0,
          // },
          // {
          //   'a': 2018,
          //   'b': '苏B89H98',
          //   'c': 0,
          // },
          // {
          //   'a': 2019,
          //   'b': '苏BGDS09',
          //   'c': 0,
          // },
          // {
          //   'a': 2021,
          //   'b': '苏BGE09G',
          //   'c': 0,
          // },
          // {
          //   'a': 2080,
          //   'b': '苏J89U89',
          //   'c': 2387,
          //   'd': '李雷',
          // },
          // {
          //   'a': 2083,
          //   'b': '苏C89238',
          //   'c': 2108,
          //   'd': '新的',
          // },
          // {
          //   'a': 2182,
          //   'b': '冀EU4150',
          //   'c': 0,
          // },
          // {
          //   'a': 2183,
          //   'b': '冀EU4363',
          //   'c': 2569,
          //   'd': '运力2',
          // },
          // {
          //   'a': 2184,
          //   'b': '冀EU4545',
          //   'c': 2474,
          //   'd': '运力3',
          // },
          // {
          //   'a': 2185,
          //   'b': '冀EU4916',
          //   'c': 0,
          // },
          // {
          //   'a': 2347,
          //   'b': '冀EV7707',
          //   'c': 0,
          // },
          // {
          //   'a': 2348,
          //   'b': '冀EV7062',
          //   'c': 3177,
          //   'd': '李慧',
          // },
          // {
          //   'a': 2359,
          //   'b': '苏AE4856',
          //   'c': 0,
          // },
          // {
          //   'a': 2360,
          //   'b': '苏AE4962',
          //   'c': 0,
          // },
          // {
          //   'a': 2361,
          //   'b': '苏AE5340',
          //   'c': 0,
          // },
          // {
          //   'a': 2362,
          //   'b': '苏AE5411',
          //   'c': 0,
          // },
          // {
          //   'a': 2364,
          //   'b': '苏AE8453',
          //   'c': 0,
          // },
          // {
          //   'a': 2365,
          //   'b': '苏AE8471',
          //   'c': 0,
          // },
          // {
          //   'a': 2366,
          //   'b': '苏AE9642',
          //   'c': 0,
          // },
          // {
          //   'a': 2367,
          //   'b': '苏AE9947',
          //   'c': 0,
          // },
          // {
          //   'a': 2368,
          //   'b': '苏AG7310',
          //   'c': 0,
          // },
          // {
          //   'a': 2369,
          //   'b': '苏AH2150',
          //   'c': 2786,
          //   'd': '108驾驶',
          // },
          // {
          //   'a': 2370,
          //   'b': '苏AH5232',
          //   'c': 2976,
          //   'd': '周云',
          // },
          // {
          //   'a': 2371,
          //   'b': '苏AH9551',
          //   'c': 0,
          // },
          // {
          //   'a': 2762,
          //   'b': '苏J23554',
          //   'c': 0,
          // },
          // {
          //   'a': 2764,
          //   'b': '苏A1B103',
          //   'c': 0,
          // },
          // {
          //   'a': 2765,
          //   'b': '苏A1A033',
          //   'c': 4596,
          //   'd': '108新',
          // },
          // {
          //   'a': 2766,
          //   'b': '苏A0A201',
          //   'c': 3374,
          //   'd': '新的',
          // },
          // {
          //   'a': 2767,
          //   'b': '苏A0A262',
          //   'c': 0,
          // },
          // {
          //   'a': 2768,
          //   'b': '苏A2B898',
          //   'c': 3432,
          //   'd': '测试同步',
          // },
          // {
          //   'a': 2769,
          //   'b': '冀EU3839',
          //   'c': 3929,
          //   'd': '测一下',
          // },
          // {
          //   'a': 2773,
          //   'b': '苏C48594',
          //   'c': 0,
          // },
          // {
          //   'a': 2809,
          //   'b': '苏B23432',
          //   'c': 0,
          // },
          // {
          //   'a': 2810,
          //   'b': '苏B34935',
          //   'c': 0,
          // },
          // {
          //   'a': 2811,
          //   'b': '苏B39552',
          //   'c': 0,
          // },
          {
            'a': 2852,
            'b': '苏A2B080',
            'c': 0,
          },
          {
            'a': 2853,
            'b': '苏A9A220',
            'c': 0,
          },
          {
            'a': 2854,
            'b': '苏A9B047',
            'c': 0,
          },
          {
            'a': 2855,
            'b': '苏A9B636',
            'c': 0,
          },
          {
            'a': 2980,
            'b': '苏AY7L96',
            'c': 1,
          },
          {
            'a': 2983,
            'b': '苏AY7L01',
            'c': 1,
          },
          {
            'a': 2984,
            'b': '苏AY7L02',
            'c': 1,
            'd': '驾驶云大屏',
          },
          {
            'a': 3259,
            'b': '苏AP7440',
            'c': 1,
          },
          {
            'a': 3260,
            'b': '苏AU2173',
            'c': 1,
          },
          {
            'a': 3261,
            'b': '苏AP2172',
            'c': 1,
          },
          {
            'a': 3270,
            'b': '苏AN1429',
            'c': 1,
          },
          {
            'a': 3271,
            'b': '苏AL0515',
            'c': 2,
          },
          {
            'a': 3273,
            'b': '苏AM4001',
            'c': 2,
          },
          {
            'a': 3274,
            'b': '苏EC1111',
            'c': 2,
          },
          {
            'a': 3314,
            'b': '苏D34545',
            'c': 3,
          },
        ],
        },
      };
      const { data } = res;
      if (data.d && data.d.length !== 0) {
        this.allCarList = data.d;
        const cars = data.d.map(d => d.b);
        // this.socket.readyState
        this.sendSocket(cars);
      }
      // });
    },
    // 所有车牌号录入进行ws通信
    sendSocket(cars) {
      const carObj = {
        a: cars,
      };
      this.$store.dispatch('carMonitor/SOCKET_SEND', { msg: carObj, type: [0, 2, 0] });
    },
    // 获取装卸货地址 便于画起始点
    getAddressList() {
      // 此处暂调货单详情接口获取地址信息//this.currentGoodsOrder.id
      supplyTask.orderGoodsDetail(this.currentGoodsOrder.id).then(res => {
        if (res.goodsAddressVOList.length) {
          this.loadTableData = res.goodsAddressVOList.filter((val) => +val.type === 1);
          this.unloadTableData = res.goodsAddressVOList.filter((val) => +val.type === 2);
        }
      })
        .finally(() => {
          this.drawStartAndEnd();
        });
      // const res = [
      //   {
      //     'type': 1,
      //     'addrName': '南京',
      //     'addrRegion': '江苏省南京市',
      //     'addrDetail': '龙蟠路111号(南京站地铁站3号口步行110米)',
      //     'addrPoint': '118.797499,32.087104',
      //     'contacter': '南京人',
      //     'contacterPhone': '15999999991',
      //   },
      //   {
      //     'type': 2,
      //     'addrName': '北京2',
      //     'addrRegion': '北京市',
      //     'addrDetail': '毛家湾胡同甲13号',
      //     'addrPoint': '116.427354,39.902830',
      //     'contacter': '北京人2',
      //     'contacterPhone': '15999999992',
      //   },
      // ];
      // this.loadTableData = res.filter((val) => +val.type === 1);
      // this.unloadTableData = res.filter((val) => +val.type === 2);
      // this.drawStartAndEnd();
    },
    // 开始根据起始点规划路线
    drawStartAndEnd() {
      if (this.mapInstance) {
        this.mapInstance.clearMap(); // 清空画布
        // 画起点 终点
        this.loadTableData && this.drawMarker(this.loadTableData[0], 0);
        this.unloadTableData && this.drawMarker(this.unloadTableData[0], 1);
        // 根据车辆数据加载地图车实时位置
        // this.allCarLocationList.map((item) => {
        //   this.drawCarMark(item.a, `${item.b / 1000000},${item.c / 1000000}`);
        // });
        // 根据装卸货经纬度 绘制导航规划线路
        this.drawPlanLine(this.loadTableData[0].addrPoint, this.unloadTableData[0].addrPoint);
        this.mapInstance.setFitView();
      }
    },
    // 打点
    drawMarker(data, type) {
      // type:0-开始 1-结束 2-中间汽车点
      const marker = new this.AMap.Marker();
      if (+type === 0) {
        const startIcon = new this.AMap.Icon({
          // size: new this.AMap.Size(25, 34),
          // image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // imageSize: new this.AMap.Size(135, 40),
          // imageOffset: new this.AMap.Pixel(-7, -3),
          size: new this.AMap.Size(32, 33),
          image: require('@/assets/images/monitor/car-zhuang.svg'),
          imageSize: new this.AMap.Size(32, 33),
          imageOffset: new this.AMap.Pixel(0, 0),
        });
        marker.setIcon(startIcon);
      } else if (+type === 1) {
        const endIcon = new this.AMap.Icon({
          // size: new this.AMap.Size(25, 34),
          // image: "//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
          // imageSize: new this.AMap.Size(135, 40),
          // imageOffset: new this.AMap.Pixel(-97, -3),
          size: new this.AMap.Size(32, 33),
          image: require('@/assets/images/monitor/car-xie.svg'),
          imageSize: new this.AMap.Size(32, 33),
          imageOffset: new this.AMap.Pixel(0, 0),
        });
        marker.setIcon(endIcon);
      }
      // else if (+type === 2) {
      // 如果已卸货 则不展示行车图标+车牌号
      // if (!this.isXie) {
      //   const carIcon = new this.AMap.Icon({
      //     // size: new this.AMap.Size(25, 34),
      //     size: new this.AMap.Size(24, 24),
      //     image: require('@/assets/images/monitor/carPng2.png'),
      //     imageSize: new AMap.Size(24, 24),
      //     // imageOffset: new this.AMap.Pixel(-97, -3),
      //   });
      //   marker.setIcon(carIcon);
      //   const markHtml = `<div class="bg-car">${this.clickCarNo}</div>`;
      //   marker.setLabel({
      //     content: markHtml, // 设置文本标注内容
      //     direction: 'top', // 设置文本标注方位
      //   });
      // }
      // }
      if (data.addrPoint) {
        const lnglat = new this.AMap.LngLat(...data.addrPoint.split(','));
        marker.setPosition(lnglat);
        if (+type !== 2) {
          this.mapInstance.add(marker);
        } else {
          // 如果已卸货 则不展示行车图标+车牌号
          // if (!this.isXie) {
          //   this.mapInstance.add(marker);
          // }
        }
      }
      this.mapInstance.setFitView();
    },
    // 绘制导航规划线路
    drawPlanLine(startLnglat, terminalLnglat) {
      if (startLnglat && terminalLnglat) {
        this.driving = new AMap.Driving({
          map: this.mapInstance,
          policy: this.AMap.DrivingPolicy.LEAST_TIME,
          hideMarkers: true,
          showTraffic: false,
          // outlineColor: "red",
        });
        // this.driving.search([{ keyword: this.startAddress }, { keyword: this.terminalAddress }]);
        const start = [+startLnglat.split(',')[0], +startLnglat.split(',')[1]];
        const end = [+terminalLnglat.split(',')[0], +terminalLnglat.split(',')[1]];
        this.driving.search(start, end);
      }
    },
    // 车辆点位移动
    carMoving(mark, text, text1, carLngLat, speed) {
      const lnglat = new AMap.LngLat(...carLngLat.split(','));
      mark.moveTo(lnglat, speed);
      text.moveTo(lnglat, speed);
      text1.moveTo(lnglat, speed);
    },
    // 根据车牌号回查车辆id
    getCarId(carNo) {
      // console.log(carNo, 'carNo');
      const currentCar = this.allCarList.find((item) => {
        return item.b === carNo;
      });
      // console.log(currentCar, 'currentCar');
      return currentCar.c;
    },
    // 根据车牌号回查车单（辆）信息
    getCarInfo(carNo) {
      const currentCar = this.allCarList.find((item) => {
        return item.b === carNo;
      });
      return currentCar;
    },
    // 根据经纬度查询地址失败
    // getAddress(lnglat) {
    //   return new Promise((resolve, reject) => {
    //     this.geocoder.getAddress(lnglat, (status, result) => {
    //       let address;
    //       if (status === 'complete' && result.regeocode) {
    //         address = result.regeocode.formattedAddress;
    //         resolve(address);
    //       } else {
    //         console.log('根据经纬度查询地址失败');
    //         address = '未知道路';
    //         reject(address);
    //       }
    //     });
    //   });
    // },
    // 画点 将车辆点位画入地图中
    drawCarMark(carNo, carLngLat, angle, speed) {
      console.log(carNo, carLngLat, angle, speed, 'carNo, carLngLat, angle, speed-----------');
      const carId = this.getCarId(carNo);
      // let carClass = "bg-speed";
      // const markHtml = `<div class="bg-car" style="transform: rotate(-${angle}deg)">${carNo}</div>`;
      const markHtml2 = `<div class="bg-car2" >${carNo}</div>`;
      const markHtml3 = `<div class="${this.carClassName(speed)}" ><div class="speed-item1" >${speed}</div><div class="speed-item2" >km/h</div></div>`;
      let img;
      switch (true) {
        case +carId === 0:
          img = require('@/assets/images/monitor/carPng.png'); // dai
          break;
        case +carId > 0:
          img = require('@/assets/images/monitor/carPng2.png'); // zhong
          break;
        default:
          img = require('@/assets/images/monitor/carPng3.png'); // finish
          break;
      }
      // 创建一个 Icon
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(24, 24),
        // 图标的取图地址
        image: img,
        // 图标所用图片大小
        imageSize: new AMap.Size(24, 24),
        // 图标取图偏移量
      });
      const marker = new AMap.Marker({
        position: new AMap.LngLat(...carLngLat.split(',')),
        icon: startIcon, // 添加 Icon 图标 URL
        title: `${carNo}`,
        offset: new AMap.Pixel(-9, -9),
        angle,
        autoRotation: true,
      });
      marker.on('click', (e) => {
        this.MapClick(carNo, carLngLat, angle, speed, e);
      });
      // 获取车事件
      // let carMsg = {
      //   a:carNo,
      //   b:e.lnglat.lat *1000000,
      //   c:e.lnglat.lng*1000000
      // }
      //      let carLineView2 = toView(lineObj, 0, 0x0a, 0); //返回 0,a 实时位置
      // socket.send(carView);
      // 将 markers 添加到地图
      this.mapInstance.add(marker);
      const text = new AMap.Text({
        text: markHtml2,
        position: new AMap.LngLat(...carLngLat.split(',')),
        anchor: 'bottom-left',
        offset: new AMap.Pixel(-18, -20),
      });
      text.on('click', (e) => {
        this.MapClick(carNo, carLngLat, angle, speed, e);
      });
      this.mapInstance.add(text);
      const text1 = new AMap.Text({
        text: markHtml3,
        position: new AMap.LngLat(...carLngLat.split(',')),
        anchor: 'bottom-left',
        offset: new AMap.Pixel(-60, -9),
      });
      text1.on('click', (e) => {
        this.MapClick(carNo, carLngLat, angle, speed, e);
      });
      this.mapInstance.add(text1);
      // 设置label标签
      // marker.setLabel({
      //   content: markHtml, //设置文本标注内容
      //   direction: "top", //设置文本标注方位
      // });
      // this.mapInstance.setFitView();
      return [marker, text, text1];
    },
    // 地图上车辆点击 需要车单轨迹弹窗打卡
    MapClick(carNo, carLngLat, angle, speed, e) {
      // console.log(speed, 'speed');
      // console.log(carNo, carLngLat, angle, speed, e, 'carNo---MapClick');
      // this.dialogMarkerData.carNo = carNo;
      // const carIdCurrent = this.getCarId(carNo);
      // console.log(carIdCurrent, 'carIdCurrent');
      this.currentClickCarInfo = this.getCarInfo(carNo);
      this.dialogVisible = true;
      // this.dialogMarkerData.id = carIdCurrent;
      // if (this.dialogMarkerData.id === 0) {
      //   this.dialogMarkerData.status = '空闲';
      // } else {
      //   this.dialogMarkerData.status = '运输中';
      // }
      // this.getAddress([e.lnglat.lng, e.lnglat.lat]).then((res) => {
      //   this.dialogMarkerData.address = res;
      // });
      // this.dialogMarkerData.lnglat = `${e.lnglat.lng},${e.lnglat.lat}`;
      // this.dialogMarkerData.speed = this.carMap.get(carNo).d;
      // if (this.dialogMarkerData.speed === 0) {
      //   this.dialogMarkerData.carStatus = '停车中';
      // } else {
      //   this.dialogMarkerData.carStatus = '行驶中';
      // }
      // this.getCarTrackDataNew(
      //   {
      //     a: carNo || undefined,
      //     b: new Date(new Date().toLocaleDateString()).getTime(), // 取0点时间
      //     c: Date.parse(new Date()), // 取当前时间
      //     // d: 2,
      //   },
      //   {
      //     a: this.dialogMarkerData.id,
      //     b: new Date(new Date().toLocaleDateString()).getTime(), // 取0点时间
      //     c: Date.parse(new Date()), // 取当前时间
      //     d: 2,
      //   },
      // );
      // if (this.dialogMarkerData.id) {
      //   refreshDetail(this.dialogMarkerData.id)
      //     .then((res) => {
      //       this.form = res.d || {};
      //       const am = this.form.am && this.form.am.split(',');
      //       this.allDistence = this.loadCarLine([e.lnglat.lng, e.lnglat.lat], am);
      //     })
      //     .catch(() => {})
      //     .finally(() => {
      //       this.dialogMarker = true;
      //     });
      //   getTptOrderSelfDetail({
      //     orderid: this.dialogMarkerData.id,
      //   }).then((res) => {
      //     if (res.d) {
      //       this.orderInformation = res.d;
      //       this.newOrderId = res.d.orderDetail.a;
      //       this.trackActive = true;
      //     }
      //   });
      // } else {
      //   this.form = {};
      //   this.allDistence = '--';
      //   console.log('无订单信息，id为0');
      //   this.dialogMarker = true;
      //   this.trackActive = false;
      // }
    },
    carClassName(speed) {
      if (+speed > 80) {
        return 'bg-speed-red';
      } else if (+speed <= 80 && +speed > 0) {
        return 'bg-speed-green';
      } else if (+speed === 0) {
        return 'bg-speed-gray';
      }
    },
    // 左侧选择车辆后传入车牌号
    leftSearchMark(inputSearch) {
      const val = this.carMarkerMap.get(inputSearch);
      if (val) {
        this.mapInstance.setFitView(val[1]);
      }
    },
    // 右侧点击分类状态 车牌号后 定位到具体车牌
    rightShowCarLog(data) {
      console.log('[ data---- ] >', data);
      const val = this.carMarkerMap.get(data.b);
      if (val) {
        this.mapInstance.setFitView(val[1]);
      }
    },
    // 右侧卡片选择货单事件
    selectGoodsOrder(data) {
      this.currentGoodsOrder = data;
      console.log(data.id, 'data.id');
      // 获取最新的车单 并绘制
      // this.getCarList(data.id);
      const randomNum = Math.floor(Math.random() * 10);
      console.log(randomNum, 'randomNum');
      const carList = {
        0: [
          {
            'a': 1765,
            'b': '冀EP6148',
            'c': 3082,
            'd': 'xin2',
          },
          {
            'a': 1766,
            'b': '苏A9B716',
            'c': 1056,
            'd': '萧纪聪',
          },
          {
            'a': 1767,
            'b': '津C02312',
            'c': 3087,
            'd': '新增司机',
          },
          {
            'a': 1768,
            'b': '津C02303',
            'c': 2615,
            'd': '陈家佳',
          },

        ],
        1: [
          {
            'a': 2182,
            'b': '冀EU4150',
            'c': 0,
          },
          {
            'a': 2183,
            'b': '冀EU4363',
            'c': 2569,
            'd': '运力2',
          },
          {
            'a': 2184,
            'b': '冀EU4545',
            'c': 2474,
            'd': '运力3',
          },
          {
            'a': 2185,
            'b': '冀EU4916',
            'c': 0,
          },
          {
            'a': 2347,
            'b': '冀EV7707',
            'c': 0,
          },
        ],
        2: [
          {
            'a': 2348,
            'b': '冀EV7062',
            'c': 3177,
            'd': '李慧',
          },
          {
            'a': 2359,
            'b': '苏AE4856',
            'c': 0,
          },
          {
            'a': 2360,
            'b': '苏AE4962',
            'c': 0,
          },
          {
            'a': 2361,
            'b': '苏AE5340',
            'c': 0,
          },
        ],
        3: [
          {
            'a': 2362,
            'b': '苏AE5411',
            'c': 0,
          },
          {
            'a': 2364,
            'b': '苏AE8453',
            'c': 0,
          },
          {
            'a': 2365,
            'b': '苏AE8471',
            'c': 0,
          },
          {
            'a': 2366,
            'b': '苏AE9642',
            'c': 0,
          },
        ],
        4: [
          {
            'a': 2367,
            'b': '苏AE9947',
            'c': 0,
          },
          {
            'a': 2368,
            'b': '苏AG7310',
            'c': 0,
          },
          {
            'a': 2369,
            'b': '苏AH2150',
            'c': 2786,
            'd': '108驾驶',
          },
        ],
        5: [
          {
            'a': 2370,
            'b': '苏AH5232',
            'c': 2976,
            'd': '周云',
          },
          {
            'a': 2371,
            'b': '苏AH9551',
            'c': 0,
          },
          {
            'a': 2762,
            'b': '苏J23554',
            'c': 0,
          },
          {
            'a': 2764,
            'b': '苏A1B103',
            'c': 0,
          },
        ],
        6: [
          {
            'a': 2765,
            'b': '苏A1A033',
            'c': 4596,
            'd': '108新',
          },
          {
            'a': 2766,
            'b': '苏A0A201',
            'c': 3374,
            'd': '新的',
          },
          {
            'a': 2767,
            'b': '苏A0A262',
            'c': 0,
          },
        ],
        7: [
          {
            'a': 2768,
            'b': '苏A2B898',
            'c': 3432,
            'd': '测试同步',
          },
          {
            'a': 2769,
            'b': '冀EU3839',
            'c': 3929,
            'd': '测一下',
          },
          {
            'a': 2773,
            'b': '苏C48594',
            'c': 0,
          },
        ],
        8: [
          {
            'a': 2809,
            'b': '苏B23432',
            'c': 0,
          },
          {
            'a': 2810,
            'b': '苏B34935',
            'c': 0,
          },
          {
            'a': 2811,
            'b': '苏B39552',
            'c': 0,
          },
        ],
        9: [
          {
            'a': 1769,
            'b': '冀D3F819',
            'c': 2598,
            'd': '毛晓晓',
          },
          {
            'a': 1856,
            'b': '苏C98K89',
            'c': 0,
          },
          {
            'a': 1857,
            'b': '苏AGDE89',
            'c': 0,
          },
          {
            'a': 2015,
            'b': '苏AGDS98',
            'c': 0,
          },
        ],
        10: [
          {
            'a': 2018,
            'b': '苏B89H98',
            'c': 0,
          },
          {
            'a': 2019,
            'b': '苏BGDS09',
            'c': 0,
          },
          {
            'a': 2021,
            'b': '苏BGE09G',
            'c': 0,
          },
          {
            'a': 2080,
            'b': '苏J89U89',
            'c': 2387,
            'd': '李雷',
          },
          {
            'a': 2083,
            'b': '苏C89238',
            'c': 2108,
            'd': '新的',
          },
          {
            'a': 2182,
            'b': '冀EU4150',
            'c': 0,
          },
        ],
      };
      if (carList[randomNum].length) {
        this.getAddressList();
        this.$store.dispatch('carMonitor/CLOSE_SOCKED');
        this.carMap = new Map();
        this.carMarkerMap = new Map();
        setTimeout(() => {
          this.socketInitAndOpen();
          this.allCarList = carList[randomNum];
          const cars = carList[randomNum].map(d => d.b);
          this.sendSocket(cars);
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
.amap-marker-label {
  border: none;
  background: none;
}
.amap-overlay-text-container {
  border: none;
  background: none;
}
// .bg-car {
//   height: 33px;
//   width: 78px;
//   text-align: center;
//   font-size: 12px;
//   color: #fff;
//   line-height: 26px;
//   background: url("~@/assets/images/carBg.png") no-repeat;
//   background-size: 100% 100%;
// }
.bg-car2 {
  height: 26px;
  width: 84px;
  text-align: center;
  font-size: 14px;
  color: #04122b;
  line-height: 26px;
  // background: #0d1b36;
  // background: #4385ff;
  background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 2px;
}
.bg-speed-red {
  width: 48px;
  height: 48px;
  border: 2px solid #d52137;
  border-radius: 50% 50%;
  background: #ef5063;
  color: #ffffff;
}
.bg-speed-green {
  width: 48px;
  height: 48px;
  border: 2px solid #10ce66;
  border-radius: 50% 50%;
  background: #ffffff;
  color: #16c375;
}
.bg-speed-gray {
  width: 48px;
  height: 48px;
  border: 2px solid #58595c;
  border-radius: 50% 50%;
  background: #ffffff;
  color: #58595c;
}
.speed-item1 {
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  padding-top: 6px;
}
.speed-item2 {
  text-align: center;
  font-size: 12px;
  line-height: 12px;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .el-button,
  .el-input__inner {
    border-radius: 0;
  }
}
.map-container {
  height: calc(100vh - 130px);
  .fullMap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .map {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
  .left-card{
    // width: 350px;
    position: absolute;
    top: 10px;
    left: 12px;
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
  .right-card{
    // width: 350px;
    position: absolute;
    top: 10px;
    right: 12px;
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
}
.map-dialog-wrap {
  transform: translateZ(101px) !important;
  display: flex;
  flex-direction: column;
  ::v-deep .el-dialog {
    margin:auto!important;
  }
}
</style>
