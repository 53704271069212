import request from '@/plugins/axios';
import requestBety from '@/plugins/requestBety';
export const getCarTrack = (data) => {
  // 入参处理
  return request.post(`/pass/carnumber/lgt?md=010&cmd=028`, data).then((res) => {
    //  回参处理
    return res;
  });
};
export const getCarTrackById = (data) => {
  // 入参处理
  return request.post(`/saas/checking/carTrack?md=006&cmd=024`, data).then((res) => {
    //  回参处理
    return res;
  });
};
// 获取车牌号列表
export const getCarList = (data, options = {}) => {
  return requestBety.post(`/saas/tpt/car/status/list?md=059&cmd=036`, data, options).then((res) => {
    return res;
  });
};
// 通过车牌号获取轨迹
export const getCarTrackNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=040`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};
// 获取事件
export const getCarEventNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=043`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};
export const getWeather = (data, options = {}) => {
  return request.post(`/saas/tpt/weather/day?md=059&cmd=016`, data, options).then((res) => {
    return res;
  });
};
export const getCarDetailNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=045`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};
// 通过单id获取轨迹
export const getCarTrackByIdNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=042`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};
// 位置
export const getCarPostionNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=041`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};
// 轨迹事件
export const getCarEventPointNew = (data, options = {}) => {
  // 入参处理
  return requestBety.post(`/pass/carnumber/lgt?md=016&cmd=042`, data, options).then((res) => {
    //  回参处理
    return res;
  });
};

export const getCarInfo = (data) => {
  // 入参处理
  return request.post(`/saas/tpt/driver/order/car/milestone/list?md=058&cmd=080`, data).then((res) => {
    //  回参处理
    return res;
  });
};
// 获取胎压
export const getCarAlarmData = (data) => {
  // 入参处理
  return request.post(`/pass/carnumber/get/alarmData?md=010&cmd=030`, data).then((res) => {
    //  回参处理
    return res;
  });
};
import { getTptOrderSelfDetailType } from './transportType';
// 获取车单详情md=0x3A&cmd=072
export const refreshDetail = (carOrderId) => request.get(`/saas/tpt/order/self/car/detail/${carOrderId}?md=058&cmd=072`);
export const getTptOrderSelfDetail = (data) => {
  return request.get(`/saas/v2/tpt/order/self/car/detail/${data.orderid}?md=063&cmd=072`).then((res) => {
    //  回参处理
    res = getTptOrderSelfDetailType.res(res);
    return res;
  });
};

// 获取货单地址列表
export const getOrderAddress = (data) => {
  return request.get(`/saas/v2/gds/order/address/list/${data.orderid}?md=063&cmd=016`).then((res) => {
    //  回参处理
    // res = getOrderAddressType.res(res);
    return res;
  });
};

// 分享货单列表
export const syncShareGoodsOrderList = (params) => {
  return request.post('/sync/share/goods/order/list', params);
};

// 分享货单详情
export const syncShareGoodsOrderDetail = (params) => {
  return request.post('/sync/share/goods/order/detail', params);
};

// 分享车单详情
export const syncShareCarOrderDetail = (params) => {
  return request.post('/sync/share/car/order/detail', params);
};
